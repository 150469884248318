import {
  CoupleAndPieChartLarge,
  CoupleAndPieChartSmall,
} from 'component-library/images/CoupleAndPieChart';
import {
  FivePeopleWalkingOutsideLarge,
  FivePeopleWalkingOutsideSmall,
} from 'component-library/images/FivePeopleWalkingOutside';
import {
  PersonalPlanLarge,
  PersonalPlanSmall,
} from 'component-library/images/PersonalPlan';
import {
  SpendBarChartLarge,
  SpendBarChartSmall,
} from 'component-library/images/SpendBarChart';
import React from 'react';

export const featuresHeading = 'Together, we can give your clients:';

export const topFeatureSection = {
  heading: 'Dedicated financial fiduciaries',
  body:
    "Your clients' dedicated advisor will help them manage their investments, spend smarter, and navigate key financial decisions to help with their financial success.",
  imageLarge: <PersonalPlanLarge />,
  imageSmall: <PersonalPlanSmall />,
};

export const featuresSections = [
  {
    heading: 'Free planning consultations',
    body:
      'We make it easy for clients to start through a free, no-obligation financial consultation to make sure we are a good fit for their needs.',
    imageLarge: <CoupleAndPieChartLarge />,
    imageSmall: <CoupleAndPieChartSmall />,
  },
  {
    heading: 'Retirement income specialists',
    body:
      'All advisors are directly employed by Retirable and U.S. based, licensed fiduciaries, specializing in holistic retirement planning and income generation.',
    imageLarge: <SpendBarChartLarge />,
    imageSmall: <SpendBarChartSmall />,
  },
  {
    heading: 'Industry low fees',
    body:
      'We only charge 0.75% on the first $500k of assets, and nothing on anything above. There is no minimum savings requirement enabling us to help all your clients.',
    imageLarge: <FivePeopleWalkingOutsideLarge />,
    imageSmall: <FivePeopleWalkingOutsideSmall />,
  },
];
