import { Grid } from 'common/layouts/grid/Grid';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { heading, items } from './content';
import { GetStartedItem } from './GetStartedItem';

const Wrapper = styled(ShowOn)`
  max-width: 1568px;
  padding: 80px 40px;
  margin: auto;
`;

const StyledGrid = styled(Grid)`
  border-radius: 24px;
  background-color: ${colors.navyBlue};
  padding: 34px 46px;
`;

export const GetStartedLarge = () => (
  <Wrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Flex column justifyContent="center">
      <ResponsiveHeading variant={3} marginBottom={80}>
        {heading}
      </ResponsiveHeading>
    </Flex>
    <StyledGrid cols={3} gap={4}>
      {items.map((item, idx) => (
        <GetStartedItem {...item} key={idx} />
      ))}
    </StyledGrid>
  </Wrapper>
);
