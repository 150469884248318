import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const FivePeopleWalkingOutsideLarge = () => (
  <StaticImage
    src="./assets/five-people-walking-outside-large.webp"
    alt="Five people walking together outside"
  />
);

export const FivePeopleWalkingOutsideSmall = () => (
  <StaticImage
    src="./assets/five-people-walking-outside-small.webp"
    alt="Five people walking together outside"
  />
);
