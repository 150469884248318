import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { StaticImage } from 'gatsby-plugin-image';
import { WrapperLarge } from 'page-components/about-us/shared/WrapperLarge';
import React, { FC } from 'react';
import styled from 'styled-components';

import { hubSpotUrl } from '../../financial-decisions/utils';
import { body, heading } from './content';

const Text = styled(Flex)`
  flex-basis: 50%;
`;

const HeadingAndBody = styled.div`
  max-width: 790px;
  margin: 0 auto;
  padding: 12% 40px;
`;

const Image = styled(Flex)`
  flex-basis: 50%;
`;

export const HeroLarge = () => (
  <WrapperLarge maxWidth={1728} spacing={0} padding="0">
    <Flex>
      <Text column>
        <HeadingAndBody>
          <ResponsiveHeading variant={1} marginBottom={24}>
            {heading}
          </ResponsiveHeading>
          <ResponsiveBody variant={1} marginBottom={48}>
            {body}
          </ResponsiveBody>
          <Flex alignItems="center" justifyContent="space-between">
            <GetStartedButton
              buttonText="Request More Info"
              onClick={() => window.open(hubSpotUrl, '__blank')}
            />
            <StaticImage width={250} src="../../images/ahip.webp" alt="hero" />
          </Flex>
        </HeadingAndBody>
      </Text>
      <Image>
        <StaticImage src="../../images/medicare-hero-large.webp" alt="hero" />
      </Image>
    </Flex>
  </WrapperLarge>
);
