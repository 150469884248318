import React from 'react';

import { GetStartedLarge } from './GetStartedLarge';
import { GetStartedSmall } from './GetStartedSmall';

export const GetStarted = () => (
  <>
    <GetStartedSmall />
    <GetStartedLarge />
  </>
);
