import { IconName } from 'component-library/components/icons/Icon';

export const heading = 'Help your clients while helping your business';

export const steps = [
  {
    iconName: IconName.StayOnTrack,
    heading: 'Deepen client relationships',
    body:
      'Provide your clients with a holistic health and wealth retirement solution with our complementary benefits',
  },
  {
    iconName: IconName.BoostIncome,
    heading: 'Boost annual renewals',
    body:
      "We'll build your AEP renewals into client financial plans so they know where to turn ahead of each season.",
  },
  {
    iconName: IconName.KeepMoreMoney,
    heading: 'Increase your revenue per client',
    body:
      "To make this decision even easier, you'll receive regular payouts for qualified introductions and more.",
  },
];
