import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { hubSpotUrl } from '../../financial-decisions/utils';
import { body, heading } from './content';

const HeroWrapper = styled(ShowOn)`
  padding: 56px 0 12px;
`;

const Text = styled.div`
  padding: 0 20px;
`;

const ImageContainer = styled.div`
  margin: auto;
  max-width: 400px;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Text>
      <Heading marginBottom={16} variant={4}>
        {heading}
      </Heading>
      <Body variant={3} marginBottom={32}>
        {body}
      </Body>
      <Flex column alignItems="center" marginBottom={56}>
        <GetStartedButton
          buttonText="Request More Info"
          onClick={() => window.open(hubSpotUrl, '__blank')}
          marginBottom={32}
        />
        <StaticImage width={250} src="../../images/ahip.webp" alt="hero" />
      </Flex>
    </Text>
    <ImageContainer>
      <StaticImage src="../../images/medicare-hero-small.webp" alt="hero" />
    </ImageContainer>
  </HeroWrapper>
);
