import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

const StyledFlex = styled(Flex)`
  background: ${colors.greenLight};
  border-radius: 24px;
  padding: 40px;
`;

interface GetStartedItemProps extends FlexProps {
  heading: string;
  body: string;
  icon: React.ReactNode;
}

export const GetStartedItem: FC<GetStartedItemProps> = ({
  heading,
  body,
  icon,
  ...rest
}) => (
  <StyledFlex column {...rest}>
    {icon}
    <Subheading variant={2} marginBottom={12} marginTop={64}>
      {heading}
    </Subheading>
    <Body variant={3}>{body}</Body>
  </StyledFlex>
);
