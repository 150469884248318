import {
  GetStartedButton,
  GetStartedButtonProps,
} from 'component-library/components/buttons/GetStartedButton';
import {
  Dynamic,
  Holistic,
  Personalized,
} from 'component-library/components/icons';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

export const hubSpotUrl =
  'https://23630958.hubspotpagebuilder.com/medicare/partners/form';

export const financialDecisionsHeading =
  'Your clients are facing new financial decisions';

export const financialDecisionsBody =
  "We'll help them navigate the financial complexities of retirement so you can focus on helping them with their healthcare needs.";

export const FinancialDecisionsButton: FC<GetStartedButtonProps> = (props) => (
  <GetStartedButton
    buttonText="Request More Info"
    onClick={() => window.open(hubSpotUrl, '__blank')}
    {...props}
  />
);

export const financialDecisionsImage = (
  <StaticImage src="../images/financial-decisions.webp" alt="couple-dancing" />
);

export const financialDecisionsValueProps = [
  {
    body: 'When should I elect Social Security?',
    icon: <Personalized />,
  },
  {
    body: 'How should I be investing my savings?',
    icon: <Dynamic />,
  },
  {
    body: 'Am I making the right financial moves?',
    icon: <Holistic />,
  },
];
