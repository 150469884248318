import {
  LegacyGoals,
  SocialSecurity,
  Taxes,
} from 'component-library/components/icons';
import React from 'react';

export const heading = 'It’s easy to get started';

export const items = [
  {
    heading: 'Turnkey Marketing Material',
    body:
      "We'll equip you with a dedicated email address, phone number, and other marketing materials to provide your clients.",
    icon: <SocialSecurity />,
  },
  {
    heading: 'Simple Client Introductions',
    body:
      'Clients can request a consultation through a custom landing page or you can make easy intros over the phone or email.',
    icon: <LegacyGoals />,
  },
  {
    heading: 'Reporting, Revenue, & Retention',
    body:
      "We'll keep you updated on your revenue payouts and pipeline and remind your clients to contact you for each AEP season.",
    icon: <Taxes />,
  },
];
