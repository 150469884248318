import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { ManArmsCrossedOutside } from 'component-library/images/ManArmsCrossedOutside';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import {
  featuresHeading,
  featuresSections,
  topFeatureSection,
} from 'page-components/partners/features/utils';
import {
  financialDecisionsBody,
  FinancialDecisionsButton,
  financialDecisionsHeading,
  financialDecisionsImage,
  financialDecisionsValueProps,
  hubSpotUrl,
} from 'page-components/partners/financial-decisions/utils';
import { GetStarted } from 'page-components/partners/get-started';
import { heading, steps } from 'page-components/partners/help-clients/utils';
import { MedicarePartnersHero } from 'page-components/partners/hero/medicare';
import {
  BottomCta,
  FeaturesSection,
  ThreeStepSection,
  ValuePropGridSection,
} from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface MedicareProps {}

const Medicare: FC<MedicareProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background={colors.greenLight}>
      <MedicarePartnersHero />
      <ThreeStepSection
        heading={heading}
        image={<ManArmsCrossedOutside />}
        steps={steps}
      />
      <FeaturesSection
        heading={featuresHeading}
        topSection={topFeatureSection}
        sections={featuresSections}
      />
      <ValuePropGridSection
        heading={financialDecisionsHeading}
        body={financialDecisionsBody}
        image={financialDecisionsImage}
        Button={FinancialDecisionsButton}
        valueProps={financialDecisionsValueProps}
      />
      <GetStarted />
      <BottomCta
        heading="We do the work, you get the goodwill."
        body="Let's chat to see how we can help you and your clients."
        buttonText="Request More Info"
        to={hubSpotUrl}
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default Medicare;

export const Head = () => (
  <SEO
    title="Medicare Agency Partnerships"
    description="Learn more about how Medicare agencies, FMOs, and agents are partnering with Retirable to offer financial wellness benefits to their clients."
  />
);
