import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const PersonalPlanLarge = () => (
  <StaticImage
    src="./assets/personal-plan-large.webp"
    alt="Bar chart showing incomes"
  />
);

export const PersonalPlanSmall = () => (
  <StaticImage
    src="./assets/personal-plan-small.webp"
    alt="Bar chart showing incomes"
  />
);
