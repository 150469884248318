import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { heading, items } from './content';
import { GetStartedItem } from './GetStartedItem';

const StyledHeading = styled(Heading)`
  margin-bottom: 32px;
  padding: 0 20px;
`;

const ItemsWrapper = styled.div`
  background-color: ${colors.navyBlue};
  border-radius: 24px;
  padding: 40px 20px;
  margin-bottom: 40px;
`;

export const GetStartedSmall = () => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    {/* <Wrapper> */}
    <StyledHeading variant={4} marginBottom={16} marginTop={32}>
      {heading}
    </StyledHeading>
    <ItemsWrapper>
      {items.map((item, idx) => (
        <GetStartedItem
          {...item}
          key={idx}
          marginBottom={idx === items.length - 1 ? 0 : 40}
        />
      ))}
    </ItemsWrapper>
  </ShowOn>
);
